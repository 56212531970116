import React from 'react';

import Hero from 'components/Hero';
import * as styles from './styles.module.scss';
import getEmail from 'utils/get-email';



export default class EnrollPage extends React.Component {
  render() {
    const email = getEmail()

    return (
      <div className={styles.root}>
        <Hero id={styles.hero} img={this.props.heroImg}>
          <div>
            <div className={styles.name}>Contact us</div>
          </div>
        </Hero>
        <div className={styles.intro}>
      <h1 className={styles.main}>Join the waitlist!</h1>
      <p>Send us <a className={styles.emailLink} href={`mailto:${email}`} target="_blank" rel="noreferrer">an email</a> and we'll be in touch shortly.</p>
    </div>
      </div>
    );
  }
}
